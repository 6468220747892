.card-list {
  width: 85vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
@media only screen and (max-width: 400px) {
  .card-list {
    display: flex;
    flex-direction: column;
  }
}
