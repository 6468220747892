.search-box {
  -webkit-appearance: none;
  border: none;
  outline: none;
  padding: 10px;
  width: 150px;
  line-height: 30px;
  margin-bottom: 30px;
  border-radius: 2em;
  width: 50vw;
  font-family: Bigelow Rules;
  font-size: 45px;
  text-align: center;
}

@media only screen and (max-width: 400px) {
  .search-box {
    width: 90vw;
  }
}
